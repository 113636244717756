@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

:root {
  --plexOrange: #e5a00d;
  --plexGrey: #4d4d4d;
  --plexDark: #282a2d;
  --plexDarker: #0d0d0d;
  --lightText: #eee;
}

a {
  text-decoration: none;
}

.btn-primary, .btn-secondary{
  background-color: var(--plexOrange);
  border-color: var(--plexDarker);
  color: var(--plexDark);
}

.btn-primary:hover, .btn-secondary:hover{
  background-color: var(--plexGrey) !important;
  border-color: var(--plexDarker) !important;
  color: var(--plexDarker) !important;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  background-color: var(--plexOrange);
  border-color: var(--plexDarker);
  color: var(--plexDark);
  opacity: .3;
}

.css-selector {
  background: linear-gradient(301deg, #4d4d4d, #282a2d, #4d4d4d);
  background-size: 600% 600%;

  -webkit-animation: backAnimation 23s ease infinite;
  -moz-animation: backAnimation 23s ease infinite;
  animation: backAnimation 23s ease infinite;
}

@-webkit-keyframes backAnimation {
  0%{background-position:0% 18%}
  50%{background-position:100% 83%}
  100%{background-position:0% 18%}
}
@-moz-keyframes backAnimation {
  0%{background-position:0% 18%}
  50%{background-position:100% 83%}
  100%{background-position:0% 18%}
}
@keyframes backAnimation {
  0%{background-position:0% 18%}
  50%{background-position:100% 83%}
  100%{background-position:0% 18%}
}

body{
  font-family: 'Open Sans', sans-serif;
  background: linear-gradient(301deg, #4d4d4d, #282a2d, #4d4d4d);
  background-size: 600% 600%;

  -webkit-animation: backAnimation 23s ease infinite;
  -moz-animation: backAnimation 23s ease infinite;
  animation: backAnimation 23s ease infinite;
  color: var(--lightText);
}

.plex-navbar{  
  color:var(--lightText);
  background-color: var(--plexDarker);
  width: 99%;
  margin: 11px auto;
  border-radius: 7px;
}

.plex-navbar .navbar-brand, .plex-navbar .profile-menu .dropdown-toggle{  
  color: var(--lightText);
  fill: var(--lightText);
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-brand img{
  margin-right:10px
}
.nav-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}


.profile-menu .dropdown-menu{
  right: 0;
  left: auto;
  position: absolute;
}

.infoModal .modal-content {
  background: var(--plexDark);
  color: var(--lightText);
}

.infoModal .modal-footer {
  border: none;
}

.listCol{
  max-width: 420px;
}

.loginText {
  text-align: center;
  font-size: 12px;
  color: #a2a2a2;
  max-width: 430px;
  margin: 10px auto 0 auto;
}


.movieCard{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.movieCard .card-title {
  flex-shrink: 0;
}

.movieModal .modal-content{
  background-color: var(--plexDark);
  color: var(--lightText);
}

.movieDetailsWrap{
  border-radius: var(--bs-modal-border-radius);
}

.movieDetailsWrap .movieDetails{
  position: relative;
  z-index: 2;
}

.movieDetailsWrap .minorInfo {
  margin-bottom: 25px;
} 

.movieDetailsWrap .minorInfo p {
  padding-bottom: 0;
  margin-bottom: 2px;
  font-size: 14px;
}

.movieDetailsWrap .minorInfo p span {
  margin-right: 15px;
}

.movieDetailsWrap .minorInfo p .rating {
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 0 6px 2px 6px;
}

.movieDetailsWrap .overview{
    padding-bottom: 40px;
    font-size: 17px;
}


.movieDetailsWrap .overlay{
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: .7;
  border-radius: var(--bs-modal-border-radius);
}



.userMovieList .mTitle, .userMovieList .mComments h6{
  margin-bottom: 0;
}
.mReleaseDate {
  font-size: 14px;
}
.userMovieList .card-body{
  padding:.5rem;
}
.userMovieList .mComments span{
  font-size: 12px;
}

.commentModal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.commentModal.active {
  display: block;
}

.commentModal textarea {
  width: 100%;
  min-height: 130px;
}

.modal-btn-toolbar{
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.posterThumb{
  border-top-left-radius: 6px;
}
.movieItemEditBtns .btn-group{
  width:100%;
}
.movieItemEditBtns .editBtns button{
  border-top-left-radius: 0;
  border-top-right-radius: 0;  
  width: 50%;
}


.movieItemEditBtns.adminView .editBtns button{
  border-radius: 0;
}
.movieItemEditBtns.adminView .adminBtns button{
  border-radius: 0;
  width: 50%;  
  font-size: 14px;
}

.submitterEmail{
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: var(--plexOrange);
  border: 1px solid var(--plexDarker);
  color: var(--plexDark);
  text-align: center;
  font-size: 12px;
}

.toggleOffCanv {
  background-color: var(--plexOrange);;
  transform: rotate(90deg);
  position: absolute;
  right: -53px;
  top: 240px;
  padding: 4px 8px;
  font-size: 14px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.toggleOffCanv:after {
  content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--plexOrange);
    z-index: -1;
    border-top-right-radius: 10px;
    transform: skewX(25deg);
    transform-origin: 100% -50%;
}
.toggleOffCanv:before {
  content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--plexOrange);
    z-index: -1;
    border-top-left-radius: 10px;
    transform: skewX(-25deg);
    transform-origin: 100% -25%;
}

.offcanvas-sm {
  visibility: visible;
}

@media (max-width: 575.98px){
  .offcanvas-sm {
    border-right: 5px solid var(--plexOrange)!important;
    padding-right:0px;
    background-color: var(--plexDark);
    color: var(--lightText);
  }
  .offcanvas-body {
    padding-right: 30px;
  }

  .offcanvas-sm.offcanvas-start {
    transform: translateX(calc(-100% + 5px));
  }

  .searchTitle{
    text-align: center;
  }
  .searchRow{
    display: flex;
    justify-content: center;
  }
  .mediaSearchBar{
    margin-bottom: 13px;
  }
}

.offcanvas-sm.show:not(.hiding), .offcanvas-sm.showing, .offcanvas-sm.hiding {
  box-shadow: 10px 0px 20px 0px rgba(0,0,0,.5);
  max-width: calc(100% - 50px);
}

.userMovieList .mediaIcon{
  height: 20px;
  width: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.movieIcon{
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20aria-hidden%3D%22true%22%20class%3D%22_1duebfh2e%20_1duebfhb%20rkbrtb0%20rkbrtb2%20rkbrtb9%22%20fill%3D%22currentColor%22%20height%3D%2248%22%20viewBox%3D%220%200%2048%2048%22%20width%3D%2248%22%3E%3Cpath%20clip-rule%3D%22evenodd%22%20d%3D%22M6%203H42C42.7957%203%2043.5587%203.31607%2044.1213%203.87868C44.6839%204.44129%2045%205.20435%2045%206V42C45%2042.7957%2044.6839%2043.5587%2044.1213%2044.1213C43.5587%2044.6839%2042.7957%2045%2042%2045H6C5.20435%2045%204.44129%2044.6839%203.87868%2044.1213C3.31607%2043.5587%203%2042.7957%203%2042V6C3%205.20435%203.31607%204.44129%203.87868%203.87868C4.44129%203.31607%205.20435%203%206%203ZM6%2042H9L9%2038H6V42ZM36%2042H12V25H36V42ZM39%2042H42V38H39V42ZM39%2035H42V30H39V35ZM39%2027H42V22H39V27ZM39%2019H42V14H39V19ZM39%2011H42V6H39V11ZM36%206L12%206L12%2022H36V6ZM6%206L9%206V11H6L6%206ZM6%2014H9V19H6L6%2014ZM6%2022H9V27H6L6%2022ZM6%2030H9V35H6L6%2030Z%22%20fill%3D%22currentColor%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat round;
  background-position: center center;
  background-size: contain;
}
.tvIcon{
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20aria-hidden%3D%22true%22%20class%3D%22%22%20fill%3D%22%22%20height%3D%2248%22%20viewBox%3D%220%200%2048%2048%22%20width%3D%2248%22%3E%3Cpath%20d%3D%22M6%205H42C42.7957%205%2043.5587%205.31607%2044.1213%205.87868C44.6839%206.44129%2045%207.20435%2045%208V34C45%2034.7957%2044.6839%2035.5587%2044.1213%2036.1213C43.5587%2036.6839%2042.7957%2037%2042%2037H6C5.20435%2037%204.44129%2036.6839%203.87868%2036.1213C3.31607%2035.5587%203%2034.7957%203%2034V8C3%207.20435%203.31607%206.44129%203.87868%205.87868C4.44129%205.31607%205.20435%205%206%205ZM6%2034H42V8H6V34Z%22%20fill%3D%22currentColor%22%20fill-rule%3D%22evenodd%22%2F%3E%3Cpath%20d%3D%22M36%2043V40H12V43H36Z%22%20fill%3D%22currentColor%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat round;
  background-position: center center;
  background-size: contain;
}

.toggleUnselected{
  opacity: .3;
}
.toggleSelected{
  pointer-events: none;
}